/* FONTS */

$default-font:                          "Segma", sans-serif;


/* COLORS */
$color-white:                           #ffffff;
$color-black:                           #000000;
$color-semiblack:                       #1a1a1a;
$transparent:                           transparent;

$color-blue-01:                         #4D81C9;
$color-blue-02:                         #0B5FA7;
$color-blue-03:                         #004BC4;
$color-blue-04:                         #71A7FF;
$color-blue-05:                         #144486;
$color-blue-06:                         #C4DDE9;
$color-blue-07:                         #C3DCFF;
$color-blue-08:                         #a0b7bf;
$color-blue-lighter:                    #f2f3fa;
$color-blue-darkest:                    #081831;
$gradient-blue:                         linear-gradient(45deg, #F0FAFF, #B2D1E0);

$color-red-01:                          #FF0000;
$color-red-02:                          #FF4848;

$color-orange-01:                       #f49500;

$color-green-01:                        #7aca6d;

$color-grey:                            #626262;


/* THEME */
$site-background:                       $color-white;
$text-color:                            $color-grey;
$divider-color:                         $color-blue-lighter;
$color-ok:                              $color-green-01;
$color-ko:                              $color-red-02;
