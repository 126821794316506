/* SEGMA */

@font-face {
  font-family: 'Segma';
  src: url('../fonts/segma/Segma-Thin.woff2') format('woff2'),
  url('../fonts/segma/Segma-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Segma';
  src: url('../fonts/segma/Segma-ExtraLight.woff2') format('woff2'),
  url('../fonts/segma/Segma-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Segma';
  src: url('../fonts/segma/Segma-Light.woff2') format('woff2'),
  url('../fonts/segma/Segma-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Segma';
  src: url('../fonts/segma/Segma-Regular.woff2') format('woff2'),
  url('../fonts/segma/Segma-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Segma';
  src: url('../fonts/segma/Segma-Medium.woff2') format('woff2'),
  url('../fonts/segma/Segma-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Segma';
  src: url('../fonts/segma/Segma-SemiBold.woff2') format('woff2'),
  url('../fonts/segma/Segma-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Segma';
  src: url('../fonts/segma/Segma-Bold.woff2') format('woff2'),
  url('../fonts/segma/Segma-Bold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Segma';
  src: url('../fonts/segma/Segma-Black.woff2') format('woff2'),
  url('../fonts/segma/Segma-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
