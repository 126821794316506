* {
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    box-sizing: border-box;
}

a {
    color: unset;
    text-decoration: none;
}

.container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: padding 0.3s ease-out;
    //max-width: 1440px;
    padding-left: 0;
    padding-right: 0;

    &.full {
        max-width: 100%;
    }

    @media screen and (max-width: $breakpoint-max-width) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media screen and (max-width: $breakpoint-smartphone) {
        padding-left: 20px;
        padding-right: 20px;
    }

    .row {
        position: relative;
        width: 100%;
        max-width: 1440px;
        display: flex;
        flex-flow: row wrap;
        margin: auto;

        &.full {
            max-width: 100%;
        }

        &.default {
            flex-direction: column;
            flex-wrap: nowrap;
            margin-top: 200px;
        }

        @media screen and (max-width: $breakpoint-max-width) {

            &:not(.full) {
                padding-left: 20px;
                padding-right: 20px;
            }

        }

        .column {
            position: relative;
            display: flex;
            //padding-left: calc(20px / 2);;
            //padding-right: calc(20px / 2);

            &.dir-h {
              flex-direction: row;
            }

            &.dir-v {
              flex-direction: column;
            }

            &.d12 {
                width: 100%;
                padding-left: 0;
                padding-right: 0;
            }

            &.d11 {
                width: 91.666666%;
            }

            &.d10 {
                width: 83.333333%;
            }

            &.d9 {
                width: 75%;
            }

            &.d8 {
                width: 66.666666%;
            }

            &.d7 {
                width: 58.333333%;
            }

            &.d6 {
                width: 50%;
            }

            &.d5 {
                width: 41.666666%;
            }

            &.d4 {
                width: 33.333333%;
            }

            &.d3 {
                width: 25%;
            }

            &.d2 {
                width: 16.666666%;
            }

            &.d1 {
                width: 8.333333%;
            }

            /* TABLET */

            @media screen and (max-width: $breakpoint-tablet) {

                &.t12 {
                    width: 100%;
                    padding-left: 0;
                    padding-right: 0;
                }

                &.t11 {
                    width: 91.666666%;
                }

                &.t10 {
                    width: 83.333333%;
                }

                &.t9 {
                    width: 75%;
                }

                &.t8 {
                    width: 66.666666%;
                }

                &.t7 {
                    width: 58.333333%;
                }

                &.t6 {
                    width: 50%;
                }

                &.t5 {
                    width: 41.666666%;
                }

                &.t4 {
                    width: 33.333333%;
                }

                &.t3 {
                    width: 25%;
                }

                &.t2 {
                    width: 16.666666%;
                }

                &.t1 {
                    width: 8.333333%;
                }

            }

            @media screen and (max-width: $breakpoint-smartphone) {

                &.s12 {
                    width: 100%;
                    padding-left: 0;
                    padding-right: 0;
                }

                &.s11 {
                    width: 91.666666%;
                }

                &.s10 {
                    width: 83.333333%;
                }

                &.s9 {
                    width: 75%;
                }

                &.s8 {
                    width: 66.666666%;
                }

                &.s7 {
                    width: 58.333333%;
                }

                &.s6 {
                    width: 50%;
                }

                &.s5 {
                    width: 41.666666%;
                }

                &.s4 {
                    width: 33.333333%;
                }

                &.s3 {
                    width: 25%;
                }

                &.s2 {
                    width: 16.666666%;
                }

                &.s1 {
                    width: 8.333333%;
                }

            }


        }

    }

}

.center-h {
    justify-content: center;
}

.center-v {
    align-items: center;
}

.left {
    justify-content: flex-start;
}

.right {
    justify-content: flex-end;
}

.space {
    justify-content: space-between;
}

.d-hide {
    display: none;
}

@media screen and (max-width: $breakpoint-tablet) {

    .t-hide {
        display: none;
    }

    .d-hide {
        display: inherit;
    }

}

@media screen and (max-width: $breakpoint-smartphone) {

    .s-hide {
        display: none;
    }

}
