@import "theme";

/* ANIMATIONS */
$animation-bounce:                      cubic-bezier(.04,1.92,.51,.62);
$animation-expo:                        cubic-bezier(.06,1.15,.26,.97);


/* RESPONSIVE BREAKPOINTS */
$breakpoint-max-width:                  1440px; //limite container
$breakpoint-medium-desktop:             1200px; //tablet orizzontale
$breakpoint-laptop:                     1024px;
$breakpoint-small-desktop:              960px;
$breakpoint-tablet:                     770px; //tablet verticale
$breakpoint-smartphone:                 540px; //smartphone


/* GLOBALS */

*:not(.slick-track):not(.slick-list),
*:after,
*:before {
  transition: all 0.3s ease-out;
  box-sizing: border-box;
}

* {
  -webkit-tap-highlight-color: rgba(0,0,0,0); //togliere focus shadow blu soprattutto mobile dava fastidio
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: transparentize($color-blue-01, .7);
  box-shadow: none;
  cursor: pointer;
}

*::-webkit-scrollbar-track {
  background-color: $color-white;
  border-radius: 10px;
  background: $color-white;
}

html {
  overflow: hidden !important;
}

html,
body {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: $site-background;
  overflow-x: hidden !important;
  font-family: $default-font;
  font-size: 13px;
  line-height: 20px;
  color: $text-color;

  &.no-scroll {
    overflow: hidden;
  }

}

.hide {
  display: none !important;
}

.disabled {
  opacity: .25 !important;
  pointer-events: none !important;
}

.container {

  &.standard {
    background: $gradient-blue;
    padding: 70px 20px 20px 20px;
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;

    > .row.window {
      position: relative;
      width: 100%;
      max-width: unset;
      height: 100%;
      background-color: $color-white;
      border-radius: 10px;
      box-shadow: 0 0 50px (transparentize($color-blue-04, .56));
      flex-wrap: nowrap;
      padding: 30px;
      overflow-y: hidden;

      &.nopadding {
        padding: 0;

        .column {

          &.full {
            position: relative;
            flex: 1;
            display: flex;
            flex-direction: column;
            padding: 30px;
          }

          &.fixed {
            position: relative;
            background-color: transparentize($color-blue-04, .9);
            width: 350px;
            padding: 30px;
            display: flex;
            flex-direction: column;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            justify-content: space-between;
          }

        }

      }

    }

  }

}


/* HEADER */

header {
  height: 70px;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;

  .user-menu-container {
    position: relative;
    display: flex;
    padding: 0 10px;

    .welcome-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 20px;
      font-size: 10px;
      line-height: 130%;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 500;
      color: $color-blue-01;
      z-index: 5;

      span {
        font-size: 14px;
        letter-spacing: 1px;
        color: $color-blue-05;
        font-weight: 400;
        text-transform: capitalize;
      }

    }

    .user-image {
      border-radius: 5px;
      z-index: 5;
    }

    .submenu {
      position: absolute;
      z-index: 0;
      width: 100%;
      top: -10px;
      right: 0;
      padding: 75px 15px 15px 15px;
      background-color: $color-blue-06;
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      transform: translateY(-20px);
      opacity: 0;
      pointer-events: none;

      a {
        position: relative;
        font-size: 12px;
        line-height: 130%;
        font-weight: 500;
        color: $color-blue-05;
        letter-spacing: 1px;
        margin-bottom: 10px;
        opacity: .4;

        &:last-of-type {
          margin-bottom: 0;
        }

        &:hover {
          opacity: 1;
        }

      }

    }

    &:hover {

      .submenu {
        transform: translateY(0);
        opacity: 1;
        pointer-events: all;
      }

    }

  }

}


/* FORMS */

.fieldset {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  label {
    position: relative;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: $color-blue-02;
    margin-bottom: 8px;
    opacity: .5;

    &.invert {
      color: $color-white;
    }

  }

  &.password {

    .btn-hide-password,
    .btn-show-password {
      position: absolute;
      width: 22px;
      height: 22px;
      object-fit: contain;
      cursor: pointer;
      top: 30px;
      right: 10px;

      &:hover {
        filter: brightness(5) saturate(0);
      }

    }

    a {
      position: relative;
      margin-top: 3px;
      text-align: right;
      color: $color-white;
      font-size: 13px;
    }

  }

  &.select {

    select {
      appearance: none;
      position: relative;
      width: 100%;
      background-color: $color-blue-lighter;
      border: none;
      border-radius: 5px;
      line-height: 36px;
      font-size: 14px;
      color: $color-black;
      padding: 0 10px;
      cursor: pointer;
    }

    &:after {
      content: "";
      position: absolute;
      width: 36px;
      height: 36px;
      right: 0;
      bottom: 0;
      background-image: url("../images/icn-arrow-down.svg");
      background-repeat: no-repeat;
      background-position: center;
      pointer-events: none;
    }

    &:hover {

      &:after {
        opacity: .5;
      }

    }

  }

  &.select-container {

    mat-form-field {

      .mat-form-field-wrapper {
        padding-bottom: 0;

        .mat-form-field-flex {
          border-radius: 0;
          padding: 0;

          .mat-form-field-infix {
            width: 100%;
            padding: 0;
            border: none
          }

        }

        .mat-form-field-underline {
          display: none;
        }

      }

    }

    mat-select {
      appearance: none;
      position: relative;
      width: 100%;
      background-color: $color-blue-lighter;
      border: none;
      border-radius: 5px;
      line-height: 36px;
      font-size: 14px;
      color: $color-black;
      padding: 0 10px;
      cursor: pointer;

      .mat-select-arrow-wrapper {
        display: none;
      }

    }

    &:after {
      content: "";
      position: absolute;
      width: 36px;
      height: 36px;
      right: 0;
      bottom: 0;
      background-image: url("../images/icn-arrow-down.svg");
      background-repeat: no-repeat;
      background-position: center;
      pointer-events: none;
    }

    &:hover {

      &:after {
        opacity: .5;
      }

    }

  }

  &.number,
  .number-container {

    input {
      position: relative;
      appearance: none;
      width: 100%;
      background-color: $color-blue-lighter;
      border: none;
      border-radius: 5px;
      line-height: 36px;
      font-size: 14px;
      color: $color-black;
      padding: 0 10px;
      cursor: text;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .btn-number-add {
        position: absolute;
        width: 36px;
        height: 18px;
        right: 0;
        top: 20px;
        background-image: url("../images/icn-arrow-down.svg");
        background-repeat: no-repeat;
        background-position: center 3px;
        transform: rotate(180deg);
        z-index: 10;
        cursor: pointer;

        &:hover {
          opacity: .5;
        }

      }

    .btn-number-sub {
        position: absolute;
        width: 36px;
        height: 18px;
        right: 0;
        bottom: 18px;
        background-image: url("../images/icn-arrow-down.svg");
        background-repeat: no-repeat;
        background-position: center 3px;
        z-index: 10;
        cursor: pointer;

        &:hover {
          opacity: .5;
        }

      }

  }

}

input.text,
input.email,
input.password,
input.search {
  appearance: none;
  position: relative;
  width: 100%;
  background-color: $color-blue-lighter;
  border: none;
  border-radius: 5px;
  line-height: 36px;
  font-size: 14px;
  color: $color-black;
  padding: 0 10px;
  cursor: text;

  &.invert {
    background-color: transparentize($color-white, .9);
    color: $color-white;
  }

}

.search-field-container {
  position: relative;
  width: 100%;
  max-width: 250px;
  display: flex;
  align-items: center;

  .icon {
    position: absolute;
    width: 14px;
    left: 10px;
  }

  input.search {
    padding-left: 34px;
  }

}

.checkbox-container {
  position: relative;
  display: flex;
  width: 100%;

  .checkbox {
    position: absolute;
    opacity: 0;
    z-index: 10;

    &:checked + label:after {
      opacity: 1;
      transform: scale(1);
    }

  }

  &.invert {

    .checkbox + label {
      color: $color-white;

      &:before {
        background-color: transparentize($color-white, .9);
      }

    }

    .checkbox {

      &:checked + label:before {
        background-color: $color-blue-02;
      }

    }

  }

  .checkbox + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;

    &:before {
      content: '';
      margin-right: 10px;
      width: 18px;
      height: 18px;
      background-color: $color-blue-lighter;
      cursor: pointer;
      border-radius: 5px;
    }

    &:after {
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      background: url('../images/icn-check.svg');
      background-size: 50%;
      background-repeat: no-repeat;
      background-position: center;
      transform: scale(.2);
      opacity: 0;
      pointer-events: none;
      left: 0;
      top: 2px;
    }

    label {
      font-size: 14px;
    }

  }

}

.button {
  appearance: none;
  position: relative;
  width: 100%;
  border-radius: 10px;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.primary {
    background-color: $color-blue-03;
    line-height: 46px;
    color: $color-white;
    font-size: 18px;
    letter-spacing: 2px;
    border: none;

    &:hover {
      background-color: $color-blue-01;
    }

  }

  &.secondary {
    background-color: transparent;
    line-height: 36px;
    border: 2px solid $color-blue-01;
    color: $color-blue-01;
    font-size: 12px;
    letter-spacing: 1.5px;

    &:hover {
      opacity: .5;
    }

    &.invert {
      border-color: $color-white;
      color: $color-white;

      &:hover {
        background-color: $color-white;
        color: $color-blue-darkest;
      }

    }

    &.red {
      border-color: $color-ko;
      color: $color-ko;
    }

  }

}


/* TABLES */

.table-container {
  position: relative;
  display: flex;
  flex-direction: column;

  .table-title {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h2.title {
      margin: 0;
      font-size: 20px;
      line-height: 100%;
      font-weight: 200;
      color: $color-blue-05;
    }

  }

  ngx-table {
    position: relative;
    flex: 1;
    overflow: hidden;

    .ngx-container {
      position: absolute;
      height: 100%;

      table.ngx-table {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;

        thead {
          font-size: 10px;
          letter-spacing: .5px;
          color: $color-blue-02;
          text-transform: uppercase;

          th {
            border-color: $divider-color;
            padding: 0 7px;
            height: 40px;
            width: unset !important;

            &.commands {
              width: 44px !important;
            }

            .ngx-table__header-title {
              font-weight: 400;
              padding-left: 7.5px;

              > div {
                position: absolute;
                left: 2.5px;

                em {
                  width: 5px;
                  height: 5px;

                  &:before {
                    width: 5px;
                    height: 5px;
                    border-color: $color-blue-02;
                  }

                }

              }

              > span {
                display: none;
              }

            }

          }

        }

        tbody {
          height: 100%;

          cdk-virtual-scroll-viewport {
            height: 100%;

            .cdk-virtual-scroll-content-wrapper {
              padding-right: 20px;

              tr {
                min-height: 56px;

                &:hover {
                  background-color: $color-white;
                }

                td {
                  padding: 7px 7px 7px 14px;
                  vertical-align: middle;
                  border-color: $divider-color;
                  font-size: 12px;
                  color: $text-color;

                  &.commands {
                    width: 34px;
                  }

                  .link {
                    font-size: 14px;
                    font-weight: 500;
                    color: $color-blue-05;

                    &:hover {
                      opacity: .5;
                    }

                  }

                  .status-container {
                    position: relative;
                    display: flex;
                    align-items: center;
                    font-size: 10px;
                    text-transform: uppercase;

                    .status-color {
                      position: relative;
                      width: 7px;
                      height: 7px;
                      border-radius: 100%;
                      margin-right: 5px;

                      &.bozza {
                        background-color: $color-orange-01;
                      }

                      &.completato {
                        background-color: $color-green-01;
                      }

                    }

                  }

                  ion-button.context-menu {
                    position: relative;
                    width: 20px;
                    height: 20px;
                    display: flex;
                    flex-direction: column;

                    &::part(native) {
                      position: absolute;
                      opacity: 0;
                      width: 100%;
                      height: 100%;
                      border-radius: 0;
                      appearance: none;
                      top: 0;
                      left: 0;
                    }

                    &:after {
                      content: "";
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                      background: url("../images/icn-dots.svg");
                      background-repeat: no-repeat;
                      background-position: center;
                    }

                  }

                }

              }

            }

          }

        }

      }

    }

  }

}


/* CONTEXT MENU */

ion-popover {

  &.project-list-context-menu {

    &::part(content) {
      min-width: 150px;
      width: auto;
      padding: 15px;
      border-radius: 5px;
      box-shadow: none;
      background-color: $color-blue-07;
      left: unset !important;
      right: 98px;
      border: none;
      overflow: visible;

      &:after {
        content: "";
        position: absolute;
        right: -24px;
        width: 24px;
        height: 34px;
        margin-top: 9px;
        background-color: $color-blue-07;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background-image: url("../images/icn-dots.svg");
        background-repeat: no-repeat;
        background-position: center;
      }

    }

    a {
      position: relative;
      font-size: 12px;
      font-weight: 500;
      color: $color-blue-05;
      opacity: .4;
      letter-spacing: 1px;
      margin-bottom: 10px;

      &:hover {
        opacity: 1;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

    }

  }

}
